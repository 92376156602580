
import { Vue, Component } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { informativeStore } from "@/store/typed";
import EventCard from "@/components/Strapi/Event/Card.vue";

@Component({ components: { PageSection, EventCard } })
export default class NewsPreview extends Vue {
  get events() {
    return informativeStore.events.slice(0, 3);
  }
}
