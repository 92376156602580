
import { Vue, Component, Prop } from "vue-property-decorator";
import { StrapiService } from "@/models";
import { urlFromMedia } from "@/utils/UrlHelper";

@Component({})
export default class NewsCard extends Vue {
  @Prop() readonly value!: StrapiService;

  get url() {
    if (!this.value.cover) {
      return "";
    }
    return urlFromMedia(this.value.cover, "small");
  }

  get itemDetailRoute() {
    return { name: "service_show", params: { serviceId: this.value.id } };
  }

  goToDetail() {
    this.$router.push(this.itemDetailRoute);
  }
}
