
import { Vue, Component, Mixins } from "vue-property-decorator";

import ServicesMixin from "@/mixins/http/strapi/ServiceMixin";

import ServiceCard from "@/components/Service/Card.vue";
import PageSection from "@/components/Common/PageSection.vue";
import Service from "@/models/strapi/Service";

@Component({
  components: {
    ServiceCard,
    PageSection,
  },
})
export default class ServicesCatalogue extends Mixins(ServicesMixin) {
  private services: Service[] = [];

  async created() {
    this.services = await this.getServices({ _sort: "id" });
  }
}
