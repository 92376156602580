
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { informativeStore } from "@/store/typed";
import PageSection from "@/components/Common/PageSection.vue";
import marked from "marked";
export const MAX_LINE_COUNT = 4;
export const FONT_SIZE_TO_LINE_HEIGHT_MULTIPLIER = 1.3;

@Component({
  components: {
    PageSection,
  },
})
export default class ConfiguratorInfo extends Vue {
  @Ref() paragraph!: HTMLElement;

  private lines = 0;
  private readMore = false;

  mounted() {
    this.$nextTick(() => {
      this.lines = this.computeTextLinesCount();
    });
  }

  get introText() {
    return informativeStore.content?.configurator_text;
  }

  get totalLines() {
    return this.lines;
  }

  get sanitizedMarkdown(): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.text, "text/html");
    const images = doc.getElementsByTagName("img");
    for (var i = 0; i < images.length; i++) {
      images[i].classList.add("img-fluid");
    }
    return doc.body.innerHTML;
  }

  get text() {
    if (!this.introText) {
      return "";
    }
    return marked(
      this.largeText && !this.readMore ? this.slicedText : this.introText
    );
  }

  get slicedText() {
    if (!this.introText) {
      return null;
    }

    return `${this.introText.slice(0, 450)} ...`;
  }

  get largeText() {
    return this.lines > MAX_LINE_COUNT;
  }

  @Watch("introText")
  onIntroTextModified() {
    this.$nextTick(() => {
      this.lines = this.computeTextLinesCount();
    });
  }

  computeTextLinesCount() {
    if (!this.paragraph) {
      return 0;
    }

    const lineHeight = 18 * FONT_SIZE_TO_LINE_HEIGHT_MULTIPLIER;
    return Math.floor(this.paragraph.clientHeight / lineHeight);
  }

  toggleReadMore() {
    this.readMore = !this.readMore;
  }
}
