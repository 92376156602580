
import { Component, Vue } from "vue-property-decorator";
import { currentUserStore } from "@/store/typed";
import ServicesCatalogue from "@/components/Info/ServicesCatalogue.vue";
import PlaylistEmbed from "@/components/Common/PlaylistEmbed.vue";
import PageSection from "@/components/Common/PageSection.vue";
import StickyMenu, { StickyMenuLink } from "@/components/Common/StickyMenu.vue";
import VSvg from "@/components/Common/VSvg.vue";
import OperativeModel from "@/components/Info/OperativeModel.vue";
import EventsPreview from "@/components/Info/EventsPreview.vue";
import ConfiguratorBanner from "@/components/Info/ConfiguratorBanner.vue";
import DigitalLibraryBanner from "@/components/Info/DigitalLibraryBanner.vue";
import { informativeStore } from "../store/typed";
import { safeAsync } from "@/utils/AsyncUtil";
import ConfiguratorInfo from "@/components/Info/ConfiguratorInfo.vue";

@Component({
  components: {
    PlaylistEmbed,
    PageSection,
    DigitalLibraryBanner,
    StickyMenu,
    EventsPreview,
    ConfiguratorBanner,
    VSvg,
    OperativeModel,
    ServicesCatalogue,
    ConfiguratorInfo,
  },
})
export default class Info extends Vue {
  private links: StickyMenuLink[] = [
    { section: "#business-model", icon: "it-files", name: "Modello operativo" },
    {
      section: "#services-catalogue",
      icon: "it-folder",
      name: "Catalogo servizi",
    },
    { section: "#configurator", icon: "it-software", name: "Configurazione" },
    {
      section: "#digital-library",
      icon: "it-bookmark",
      name: "Biblioteca digitale",
    },
    { section: "#events", icon: "it-horn", name: "Eventi" },
    // { section: '#digital-stories', icon: 'it-video', name: 'PA Digital Stories' },
  ];

  async created() {
    if (!this.logged) {
      return;
    }

    const [contents, contentsError] = await safeAsync(
      informativeStore.loadContent()
    );
    const [events, eventsErrors] = await safeAsync(
      informativeStore.loadEvents()
    );
  }

  get logged() {
    return !!currentUserStore.currentUser;
  }

  get playlistId() {
    //TODO check the right one
    return informativeStore.content?.digital_stories_playlist_url;
  }

  get isMobile() {
    return this.$mq === "sm" || this.$mq === "md";
  }
}
